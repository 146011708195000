import { createSelector } from '@ngrx/store';
import { AppState } from "../app-state";
import { UserState } from "./user.reducer";

export const selectUserState = (state: AppState) => state.user;

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user
);

export const selectError = createSelector(
  selectUserState,
  (state: UserState) => state.error
);

export const selectStatus = createSelector(
  selectUserState,
  (state: UserState) => state.status
);
